export const useOverlayStore = defineStore( "overlay", () => {
  const openOverlayCount = ref( 0 );

  const overlayOpen = computed( () => openOverlayCount.value > 0 );

  function openOverlay () {
    openOverlayCount.value += 1;
  }

  function closeOverlay () {
    if ( openOverlayCount.value === 0 ) {
      throw createError( "Attempting to close overlay when none are open" );
    }

    openOverlayCount.value -= 1;
  }

  return {
    overlayOpen,
    openOverlayCount,
    openOverlay,
    closeOverlay,
  };
} );
